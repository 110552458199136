import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Fstyles%2Fsrc%2Flib%2Ftheme.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA22USW5jMQxE9zlFAVoH%2BOIfVVnZsXONhqc4cTw7U6fRd29Qq6b4VbsHqUiRlHg9nd7x5w64v%2F%2FVHw%2B7%2FloRIU5UD%2F%2FjSATpVAYLEeq8DK6J0FQqgxvFM5XBLRHapDK4I0KXl8E9EfpaZfBAhKFSGZwUP6oMXhAh5WXwkgiTTmXwigjTWmXwWvGTyuANER7zMviZCLOkMnhLhHmnMviFCE%2BiMviVCJVMp100eJfxpG97g98yTrGxmewVV9M0s96HjCcyHQw%2B5rzn%2Fdze8pQTjENlQ551fGqVwZfxy1%2FVZFAZfFOcl8HvhL32BxFtuz8JseSLKJr8rafspt%2BKWoN%2B1MnuihVRlywSwzDY%2FKMQ1fnbspqQkjVEU7KW6ErWEUPJes25hAMRXZRERBdmQUQXZ0lEF2illSjhmhAXaEOIC%2FRMiPPcaiVL%2BELUzvOVqF2eO6Jxx9%2BIxgXaE63zPBCt8zwSnfM8EZ270ZnoneeFGNzxK5FcoFuev5LqcPsy64T7kn4qdVf90jY7B532xjnowPu6%2FOhMlA5SKS2jSdRRcXuFiKn0FR19l5k0OhjOtyUkub0dUfdub080bohlyC%2FXWaSxoslipOuyJNqyZLIi6qYu6XqsmbIhuljCsYcgW8J1R15G3pG8jsHdyOuQN%2F%2BryN7%2FIHIY%2BQLkOBbmpJ9oSiU%2BE23JLiPBrzptJbzlz%2FRwe7j7%2Bw8BJQpvhwgAAA%3D%3D%22%7D"
import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Fstyles%2Fsrc%2Flib%2Fgrids.css.ts.vanilla.css%22%2C%22source%22%3A%22Ll8xeDJjd2I5MCB7CiAgZGlzcGxheTogZ3JpZDsKICBncmlkLXRlbXBsYXRlLWNvbHVtbnM6IHJlcGVhdCg0LCAxZnIpOwogIGNvbHVtbi1nYXA6IHZhcigtLV83bm1qN3IxOCk7Cn0KQG1lZGlhIChtaW4td2lkdGg6IDc0MHB4KSB7CiAgLl8xeDJjd2I5MCB7CiAgICBncmlkLXRlbXBsYXRlLWNvbHVtbnM6IHJlcGVhdCgxMiwgMWZyKTsKICAgIGNvbHVtbi1nYXA6IHZhcigtLV83bm1qN3IxZSk7CiAgfQp9%22%7D"
import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Fstyles%2Fsrc%2Flib%2Ftypography.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA63Uz26CMBzA8TtP0aMe2vQPiOJlrwKjQI1UhgSFhXdfZBrtftUU5rX5pp%2B2lJIqU13yRdG3h1B20A0%2BSZUXTYRCSrfe4JHfgMEgMAIOA98IxD3I4lLtuwi1cb3AeBwqZJwqnS%2B3t%2Baoehkhf12dL0N7pSUurvNSshnHZNPIGh%2Br%2BFPpPEKYEipk%2BUCGs0g6jWSBYSbvNANHNJuDCmFBmaO4myNy29dkhD7dJjdMPct03uXjZa1fUskh7f46bPOvvbXTwZUVFNwN7KeDggRW8umdMUX2%2BgWwk1bQcYvs9f9vBdks8KOUqYrRolQan1TaFJelU1qdl%2BMKjBfQ9OgVHO5ZCLNwBarEqcpgFYSg2sHKF6DSsBIcVDWsuA%2Bq1nIUa1D1lgrOxWznasks58roLRt%2BAIdlYAUJBwAA%22%7D"
import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Fsections%2Fsection-event-agenda%2Fsrc%2Flib%2FSectionEventAgenda.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA6VXwXLjIAy99yu47ExzII3TbdO6l%2F2THWJkmwaDB3CSdqf%2FvmMgCdjGSaeXZGLrSeLpSSjL3ZrQrFqhf3cItYRSJipsZJujPVH3GP%2FdiOZ9ozJYvPUGUjPDpMiRAk4M20P%2F9MCoqXOUrVa%2F3u6%2B7pbOZWZdNkRVTOCtNEY2Q6c769SbJINSpltOPnLEBGcC8JbLYhfELZnBhRQGhAnCP9rwZ2zJ4dhj%2Bm9MmYLCnaOQvGtEgPv9PRxCFZnM%2B%2BzwyVF7Zk4bVuw%2BeqQ98WrEIEJbUuwqJTtBcSG5VAP%2FytLyiZmgcBy861ydXCHHeblwRhFxSsfIFq2WjzrIePNdCghnlcDMQKP78ykwRX3DOXRI04sN6s2YqEExk6D3eTEKWoAwoAJ3rwl31yXuDaYVG1WW2BiVYvZ4XSNyhNdvp0dKHnKU9T%2FfO21Y%2BYE18DJHIOiwBkoaYgCtlmuNgOiwqaZSrIFVtZlNbWtTky0pmPmwIrswk9dyDwr5n4W1dBnk6HVFoUob08g4exlYw5iQDD14UkopDNbsswc%2Bt8cAViZgWZSIkOZ%2BSZkmWw50EedVhtWOaFmHrFTWbNBsP9b52X1t3W%2BloqCcwLL2iLTkzFbcvzgJa%2Brd1AmiujIX4ht99e7IHXcRWVwC50hIAQFqly7JaWDHquJzgKsUK3k4Pz6oPtf%2Bc0S5fa8NUfOTITH9nhdRxs30lJscKwgZOBpse7aUqslR17agCt%2Br6YBzlK9DAdjuOPjG3kpO041eL64opg0NFKGs01buUdOJHxVs9vp7DvUnb7tMvAIS7F8L0oaNPcXWDUyeiIrHh1zccJG5sdwPpJL3U79mlEK4VKjruxBc2YWctA81M4B1SwrIUasA9xtREEjHbLuNKXhvQqKixSm1UOxDnrvY%2FWBo7KdLfbr%2BfKiwrolyJ9eXPtifBigj6L5hAl9G%2Bao9Lmz4aPuc53y9tpx%2BXVC%2FPWpCb7uh7ZO3nV8YRrCXdAgytCXedkbY89IOfIH3FVzDj%2Bv26HyMxsDTAF2esg4NN%2BgB6ZYIZ31F3yOud9ddBtY8ZtvvzRNot2wESDFETt0KI1ejwyUGVnL%2FDwiZ6OZRqU2sVw6lyRHpjHSeJlfIAN55%2BLn5zn%2BSpv96zHV7KCpPzWW07ZlmWw7ucXKJRkilVtQo7f0wbTdRIpvDDZ2cKe%2F56z86odz%2F0A4AAA%3D%3D%22%7D"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var agendaAccordionIcon = _7a468({defaultClassName:'k2ad1ga',variantClassNames:{disabled:{true:'k2ad1gb'},type:{plus:'k2ad1gc',minus:'k2ad1gd'}},defaultVariants:{},compoundVariants:[]});
export var agendaDayWrap = 'k2ad1gw';
export var agendaItemBackground = 'k2ad1gi';
export var agendaItemBody = 'k2ad1gj _1x2cwb90';
export var agendaItemContentWrap = 'k2ad1gk';
export var agendaItemDescription = 'k2ad1gr pfiybqr';
export var agendaItemHeader = 'k2ad1g8 _1x2cwb90';
export var agendaItemSpeaker = 'k2ad1go';
export var agendaItemSpeakerName = 'pfiybqv';
export var agendaItemSpeakerPhoto = 'k2ad1gp';
export var agendaItemSpeakers = 'k2ad1gn';
export var agendaItemTag = 'k2ad1gm pfiybqz pfiybq0';
export var agendaItemTags = 'k2ad1gl pfiybqz';
export var agendaItemTime = 'k2ad1ge pfiybqn';
export var agendaItemTitle = 'k2ad1gf pfiybqn';
export var agendaItemWrap = 'k2ad1gh';
export var contentWrap = 'k2ad1g3';
export var dayContentWrap = 'k2ad1g7';
export var dayTitle = 'pfiybq3 pfiybq1';
export var dayWrap = 'k2ad1g4';
export var disclosureButton = 'k2ad1g9';
export var disclosureWrap = 'k2ad1gg';
export var downloadLink = 'k2ad1gt pfiybqv';
export var downloadLinkWrap = 'k2ad1gv';
export var headerWrap = 'k2ad1gs';
export var stickyLinkWrap = 'k2ad1gu';
export var text = 'pfiybqv';
export var title = 'k2ad1g1 pfiybq3 pfiybq1';
export var titleWrap = 'k2ad1g5';
export var wrap = 'k2ad1g0';